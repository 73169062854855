import React from "react";
import Logo from "./images/root_logo.png";

const Spinner = (props) => {
  return (
    <div className="spinner-wrapper">
      <img src={Logo} alt="Polska Live" />
      <p>{props.lang.loader}</p>
    </div>
  );
};

export default Spinner;
